export const Config = {
  Fulcrum: {
    rows: 18,
    columns: 50,
    ceils: 800,
    clue: [
      103, 106, 107, 108, 109, 110, 111, 114, 115, 116, 117, 118, 119, 120, 128,
      129, 130, 131, 132, 133, 136, 137, 138, 139, 140, 141, 144, 145, 146, 147,
      148, 149, 153, 156, 164, 170, 178, 186, 191, 194, 199, 203, 206, 214, 220,
      228, 236, 241, 244, 249, 253, 256, 264, 270, 278, 286, 291, 294, 299, 303,
      306, 314, 320, 328, 336, 341, 344, 348, 353, 356, 364, 365, 366, 367, 368,
      369, 372, 373, 374, 375, 376, 378, 379, 380, 381, 382, 386, 387, 388, 389,
      390, 391, 394, 395, 396, 397, 403, 406, 414, 428, 436, 441, 444, 448, 453,
      456, 464, 478, 486, 491, 494, 499, 503, 506, 514, 528, 536, 541, 544, 549,
      553, 556, 564, 578, 586, 591, 594, 599, 603, 606, 614, 628, 636, 641, 644,
      649, 653, 656, 664, 678, 686, 691, 694, 699, 703, 706, 714, 728, 736, 741,
      744, 749, 753, 756, 757, 758, 759, 760, 761, 764, 778, 786, 791, 794, 795,
      796, 797, 798, 799,
    ],
    mines: 12,
  },
  Beginner: {
    rows: 9,
    columns: 9,
    ceils: 81,
    mines: 10,
  },
  Intermediate: {
    rows: 16,
    columns: 16,
    ceils: 256,
    mines: 40,
  },
  Expert: {
    rows: 16,
    columns: 30,
    ceils: 480,
    mines: 99,
  },
};
